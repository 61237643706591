import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import React, {memo, useContext,useState} from 'react';

import { getFieldProps } from '../utils';
import DataModal from './DataModal';
import UserContext from '../contexts/UserContext';
import Input from '../components/shared/Input';
import ModalEvents from '../constants/ModalEvents';


const initialValues = {
    username: '',
    password: ''
};

const LoginModal = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const {loginWithFocaliz} = useContext(UserContext);




  const schema = Yup.object().shape({
      username: Yup.string().required('Votre adresse mail est requise'),
      password: Yup.string().required('Merci de saisir votre mot de passe')
  });

    return (
        <Formik
            validateOnBlur
            initialValues={initialValues}
            validationSchema={schema}
        >
            {(formik) => (
                <DataModal
                    title={{
                        create: t('dashboard.loginFocaliz'),
                    }}
                    onCreate={loginWithFocaliz}
                    event={ModalEvents.LOGIN_MODAL}
                >
                    <Input
                        label={t('shared.forms.username')}
                        className="mb-8"
                        placeholder="Saisissez votre adresse mail utilisée pour votre compte FOCALIZ ..."
                        {...getFieldProps(formik, schema, 'username')}
                    />

                    <Input
                        label={t('shared.forms.password')}
                        type='password'
                        className="mb-8"
                        placeholder="Saisissez votre mot de passe ..."
                        {...getFieldProps(formik, schema, 'password')}
                    />

                    <p className="leading-loose">{t('dashboard.loginFocalizText')}</p>
                </DataModal>
            )}
        </Formik>
  );
};

export default memo(LoginModal);
