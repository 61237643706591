import * as React from "react";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { connect, getIn } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuildingFlag,
  faFaceGrinTongueWink,
  faFaceSmileWink,
  faPenToSquare,
  faSpellCheck, faSpinner,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";
import { improveWriting } from "../../services/openai/improve-writing";
import { fixGrammar } from "../../services/openai/fix-grammar";
import { changeTone } from "../../services/openai/change-tone";
import { improvePosition } from "../../services/openai/improve-position";
import { improveDegree } from "../../services/openai/improve-degree";


const {
  Bold,
  Italic,
  Underline,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
} = EditorTools;

const itemRender = (li, itemProps) => {
  return (
    <li {...li.props}>
      {itemProps.dataItem.icon}
      <span style={{ marginLeft: '10px',fontSize: '12px' }}>{li.props.children}</span>
    </li>
  );
};


const stripHtml = (html) => {
  // Create a new div element
  const temporalDivElement = document.createElement("div");
  // Set the HTML content with the provided
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
};



class EditorWrapper extends React.Component {
  _isMounted = false;
  timeoutId = null;
  //editor = null;

  getOpenAiToolSettings(showImprovePosition,showImproveDegree,showImproveField,showImproveInstitution) {
    let items = [
      { text: 'Vérifier l\'orthographe', value: 'grammar', icon: <FontAwesomeIcon icon={faSpellCheck} /> },
      { text: 'Améliorer le texte', value: 'improve', icon: <FontAwesomeIcon icon={faPenToSquare} /> },
    ];

    // Ajouter l'item "improvePosition" uniquement si showImprovePosition est vrai
    if (showImprovePosition) {
      items.push({ text: 'Rédiger à partir du "Poste"', value: 'improvePosition', icon: <FontAwesomeIcon icon={faFaceGrinTongueWink} /> });
    }
    // Ajouter l'item "improvePosition" uniquement si showImprovePosition est vrai
    if (showImproveDegree && showImproveField && showImproveInstitution  ) {
      items.push({ text: 'Rédiger "Diplôme + Discipline + Institution"', value: 'improveDegree', icon: <FontAwesomeIcon icon={faBuildingFlag} /> });
    }

    return {
      style: "font-size",
      defaultItem: { text: "Améliorer avec IA", value: "" },
      items: items,
    };
  }


  verifyAndProcessPosition = () => {
    const { position } = this.props;
    const { degree } = this.props;
    const { field } = this.props;
    const { institution } = this.props;
    if (position) {
      console.log("Position fournie:", position);
      // Ici, vous pouvez lancer votre processus d'amélioration ou toute autre logique nécessaire
    } else {
      console.log("Aucune position fournie.");
      // Gestion du cas où la position n'est pas fournie
    }
  };

  processOpenAIResponse = (responseText) => {
    // Supposons que responseText est la chaîne contenant les propositions séparées par "---"
    const propositionsArray = responseText.split("---").map(text => stripHtml(text.trim()));
    this.setState({ propositions: propositionsArray });
  };
  handleSelectChange = (event) => {
    const action = event.value.value;

    this.setState({ selectedAction: action });

    // Appeler la fonction correspondante à l'action
    switch (action) {
      case 'improve':
        this.getHtmlAndImprove('improve');
        break;
      case 'improvePosition':
        this.getHtmlAndImprove('improvePosition');
        break;
      case 'grammar':
        this.getHtmlAndImprove('grammar');
        break;
      case 'improveDegree':
        this.getHtmlAndImprove('improveDegree');
        break;
      default:
        console.log('Action par défaut dans handleSelectChange');
      // Action par défaut ou rien
    }
  };


  // getHtmlAndImprove = async (action) => {
  //   // Vérifie si le contenu de l'éditeur est vide
  //   const { position,degree, field, institution } = this.props;
  //   if ((this.isEditorContentEmpty() && !position ) || (this.isEditorContentEmpty() && !degree && !field && !institution )) {
  //     console.log("Le contenu de l'éditeur est vide.");
  //     // Gérez le cas où le contenu est vide (par exemple, en affichant une alerte à l'utilisateur)
  //   } else {
  //     this.setState({ isLoading: true });
  //     if (action === "improve") {
  //       const currentHtml = EditorUtils.getHtml(this.editorRef.current.view.state);
  //       improveWriting(currentHtml).then(improvedText => {
  //         // Appeler processOpenAIResponse pour traiter le texte amélioré et mettre à jour l'état
  //         this.processOpenAIResponse(improvedText);
  //         this.setState({ isLoading: false });
  //       }).catch(error => {
  //         console.error("Erreur lors de l'amélioration du texte :", error);
  //         this.setState({ isLoading: false }); // Gérer l'erreur et arrêter le chargement
  //       });
  //     }
  //     if (action === "grammar") {
  //       const currentHtml = EditorUtils.getHtml(this.editorRef.current.view.state);
  //       fixGrammar(currentHtml).then(improvedText => {
  //         // Appeler processOpenAIResponse pour traiter le texte amélioré et mettre à jour l'état
  //         this.processOpenAIResponse(improvedText);
  //         this.setState({ isLoading: false });
  //       }).catch(error => {
  //         console.error("Erreur lors de l'amélioration du texte :", error);
  //         this.setState({ isLoading: false }); // Gérer l'erreur et arrêter le chargement
  //       });
  //     }
  //     if (action === "improvePosition") {
  //
  //       if (!position) {
  //         console.error("Erreur : la position est vide.");
  //         // Afficher un message d'erreur à l'utilisateur ici, si nécessaire
  //         return; // Arrête l'exécution si la position est vide
  //       }
  //
  //       this.setState({ isLoading: true }); // Commence le chargement
  //
  //       // Simulez l'appel à une fonction d'amélioration IA avec la position comme paramètre
  //       improvePosition(position).then(improvedPosition => {
  //         // Traiter la position améliorée
  //         console.log("Position améliorée:", improvedPosition);
  //         this.processOpenAIResponse(improvedPosition);
  //         this.setState({ isLoading: false });
  //         // Vous pouvez choisir de mettre à jour l'état avec la position améliorée ou de prendre d'autres actions
  //       }).catch(error => {
  //         console.error("Erreur lors de l'amélioration de la position :", error);
  //         this.setState({ isLoading: false }); // Gérer l'erreur et arrêter le chargement
  //       });
  //     }
  //
  //     if (action === "improveDegree") {
  //
  //       if (!degree && !field && !institution) {
  //         console.error("Erreur : degree + field + institution vide.");
  //         // Afficher un message d'erreur à l'utilisateur ici, si nécessaire
  //         return; // Arrête l'exécution si la position est vide
  //       }
  //
  //       this.setState({ isLoading: true }); // Commence le chargement
  //
  //       // Simulez l'appel à une fonction d'amélioration IA avec la position comme paramètre
  //       improveDegree(degree,field,institution).then(improvedDegree => {
  //         // Traiter la position améliorée
  //         console.log("Position améliorée:", improvedDegree);
  //         this.processOpenAIResponse(improvedDegree);
  //         this.setState({ isLoading: false });
  //         // Vous pouvez choisir de mettre à jour l'état avec la position améliorée ou de prendre d'autres actions
  //       }).catch(error => {
  //         console.error("Erreur lors de l'amélioration de degree :", error);
  //         this.setState({ isLoading: false }); // Gérer l'erreur et arrêter le chargement
  //       });
  //     }
  //
  //   }
  // };

  getHtmlAndImprove = async (action) => {
    const { position, degree, field, institution } = this.props;

    // Vérification initiale pour voir si une optimisation est possible
    const canImprove = !this.isEditorContentEmpty() || position || degree || field || institution;
    if (!canImprove) {
      console.log("Le contenu de l'éditeur est vide et les champs ne sont pas remplis.");
      // Afficher un message d'erreur à l'utilisateur ici, si nécessaire
      return; // Arrête l'exécution si les conditions ne sont pas remplies pour l'amélioration
    }

    this.setState({ isLoading: true }); // Commence le chargement

    try {
      let improvedText = ''; // Variable pour stocker le texte amélioré

      const currentHtml = this.isEditorContentEmpty() ? '' : EditorUtils.getHtml(this.editorRef.current.view.state);

      // Sélectionner l'action d'amélioration en fonction de l'action spécifiée
      switch (action) {
        case "improve":
          improvedText = await improveWriting(currentHtml);
          break;
        case "grammar":
          improvedText = await fixGrammar(currentHtml);
          break;
        case "improvePosition":
          if (position) improvedText = await improvePosition(position);
          break;
        case "improveDegree":
          if (degree && field && institution) improvedText = await improveDegree(degree, field, institution);
          break;
        default:
          console.log("Action non reconnue.");
      }

      // Traitement commun après l'amélioration
      if (improvedText) {
        this.processOpenAIResponse(improvedText);
      } else {
        console.error("Aucun texte amélioré reçu.");
      }
    } catch (error) {
      console.error("Erreur lors de l'amélioration :", error);
      // Afficher un message d'erreur à l'utilisateur ici, si nécessaire
    } finally {
      this.setState({ isLoading: false }); // Arrêter le chargement
    }
  };




  isEditorContentEmpty = () => {
    if (this.editorRef.current) {
      const currentHtml = EditorUtils.getHtml(this.editorRef.current.view.state);
      return !currentHtml || currentHtml.replace(/<[^>]*>/g, '').trim() === '';
    }
    return true; // Considérez le contenu comme vide si l'éditeur n'est pas encore accessible
  };

  constructor(props) {
    super(props);
    this.state = {
      propositions: [],
      selectedProposition: '',
      isLoading: false,
      selectedAction: '', // Ajouter cet état
      editorValue: getIn(props.formik.values, props.name) || '',
      improvedText: '',
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.processOpenAIResponse = this.processOpenAIResponse.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.setHtml = this.setHtml.bind(this);
    this.editorRef = React.createRef();
  }

  handleSelectionChange = (event) => {
    this.setState({ selectedProposition: event.target.value });
  };

  setHtml = () => {
    const { selectedProposition } = this.state;

    if (this.editorRef.current) {
      const view = this.editorRef.current.view;
      if (view) {
        EditorUtils.setHtml(view, selectedProposition);
      }

    }
  };
  onExecute = event => {
    const { doc, selection, html } = event.transaction;

    if (this.props.onChange && !doc.eq(event.state.doc) && this._isMounted) {
      this.timeoutId = setTimeout(() => {
        this.props.onChange({
          doc,
          selection,
          html,
          isoCode: this.props.isoCode,

        });
        //Added to make the value dirty for navigation
        // this.props.formik.setFieldValue(
        //   this.props.name,
        //   "The underlining value is dirty",
        //   false
        // );
      }, 0);
    }
  };

  initializeImprovementProcess = () => {
    const { position } = this.props;
    const { degree } = this.props;
    const { field } = this.props;
    const { institution } = this.props;
    if (position) {
     //console.log('position', position);
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.verifyAndProcessPosition();
  }

  componentWillUnmount = () => {
    window.clearTimeout(this.timeoutId);
    this._isMounted = false;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.position !== this.props.position) {
      this.initializeImprovementProcess();
    }
    if (prevProps.degree !== this.props.degree) {
      this.initializeImprovementProcess();
    }
    if (prevProps.field !== this.props.field) {
      this.initializeImprovementProcess();
    }
    if (prevProps.institution !== this.props.institution) {
      this.initializeImprovementProcess();
    }
  }


  renderCustomOpenAi = () => {
    const { handleSelectChange, showImprovePosition, showImproveDegree, showImproveField, showImproveInstitution } = this.props;
    const openAiToolSettings = this.getOpenAiToolSettings(showImprovePosition,showImproveDegree,showImproveField,showImproveInstitution);

    const OpenAiTool = EditorTools.createStyleDropDownList(openAiToolSettings);

    return (
      <OpenAiTool
        style={{
          width: "200px",
          fontSize: "14px",
          height: "30px",
        }}
        itemRender={itemRender}
        onChange={this.handleSelectChange}
      />
    );
  };

  render() {
    const { formik, name } = this.props;
    const { isLoading,improvedText } = this.state;
    const { propositions, selectedProposition } = this.state;
    const { position, degree, field, institution } = this.props;
    return (
      <div className={this.props.className}>
        <label>
      <span>
          {this.props.label}{' '}
        {this.props.isRequired && (
          <span className="opacity-75 font-normal lowercase">
              ({t('shared.forms.required')})
            </span>
        )}
        </span>
        </label>
        <Editor
          tools={[
            [Bold, Italic, Underline],
            [Undo, Redo],
            [OrderedList, UnorderedList],
            [this.renderCustomOpenAi],
          ]}
          contentStyle={{ height: 100 }}
          defaultContent={getIn(formik.values, name)}
          ref={this.editorRef}
          onExecute={this.onExecute}
        />

        <br />
        {/*{isLoading && <FontAwesomeIcon icon={faSpinner} spin />}*/}
        {/*{improvedText && (*/}

        {isLoading && (
          <div style={{ position: 'relative', width: '100%', backgroundColor: '#ddd' }}>
            <div style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '5px',
              backgroundColor: '#007bff',
              animation: 'loadingAnimation 20s infinite'
            }}></div>
          </div>
        )}
        <style>{`
          @keyframes loadingAnimation {
            0% { width: 0%; }
            50% { width: 100%; }
            100% { width: 0%; }
          }
        `}</style>
        <div>
          {(this.isEditorContentEmpty() && !position && !degree && !field && !institution) ? (
              <p style={{ color: "red" }}>Veuillez saisir du contenu dans l'éditeur avant de soumettre pour
                l'amélioration.</p>
            ) :
            (<div>
                {propositions.length > 0 && propositions.map((proposition, index) => (
                  <div key={index} className="k-radio-wrapper"
                       style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                    <input
                      type="radio"
                      className="k-radio"
                      id={`proposition-${index}`}
                      name="proposition"
                      value={proposition}
                      onChange={this.handleSelectionChange}
                      checked={selectedProposition === proposition}
                    />
                    <label className="k-radio-label" htmlFor={`proposition-${index}`} style={{ marginLeft: "10px" }}>
                      {proposition}
                    </label>
                  </div>
                ))}

                {propositions.length > 0 && (
                  <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={this.setHtml}
                    disabled={!selectedProposition}
                  >
                    <span className="k-icon k-i-arrow-60-up" />
                    Sélectionner une proposition et valider içi
                  </button>
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default connect(EditorWrapper);
