/* eslint-disable lingui/text-restrictions */

import { openai } from "./client";

/*
const PROMPT = `You are an AI writing assistant specialized in writing copy for resumes.
Do not return anything else except the text you improved. It should not begin with a newline. It should not have any prefix or suffix text.
Improve the writing of the following paragraph and return three suggestions for improvement in list form in the language of the text. separate each suggestion with
the specific line "---" ;*/


const PROMPT = `tu es un expert capable de rédiger les descriptions de missions pour un CV, spécifiquement adaptées à un intitulé d\'emploi donné.
Pour chaque intitulé d\'emploi fourni, tu devras définir succinctement les trois missions clés associées à ce poste.
Les descriptions des missions doivent être présentées sous forme de liste à puce, avec un maximum de 250 caractères par item.Voici l\'intitulé d\'emploi pour lequel j\'aimerais obtenir ces informations :Intitulé d\'emploi : {input}.
Merci de fournir, en français,  les missions clés qui correspondent à cet intitulé d\'emploi, en veillant à ce que chaque description soit concise, précise, et utile pour enrichir un CV.:
Sépare chacune des propositions par la ligne spécifique "---" ;


  Text: """{input}""";

Revised Text: """`;

export const improvePosition = async (text) => {
  const prompt = PROMPT.replace("{input}", text);

  const result = await openai().chat.completions.create({
    messages: [{ role: "user", content: prompt }],
    model: "gpt-4",
    max_tokens: 1000,
    temperature: 0,
    stop: ['"""'],
    n: 1,
  });

  if (result.choices.length === 0) {
    throw new Error(`OpenAI did not return any choices for your text.`);
  }

  return result.choices[0].message.content ?? text;
};

