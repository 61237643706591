import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';
import { getFieldProps } from '../../utils';
import DataModal from '../DataModal';
import ModalEvents from '../../constants/ModalEvents';
import EditorContainer from "../../components/shared/Container";

const initialValues = {
  name: '',
};

const HobbyModal = () => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    name: Yup.string().required(t('shared.forms.validation.required')),
  });

  return (
    <Formik
      validateOnBlur
      initialValues={initialValues}
      validationSchema={schema}
    >
      {(formik) => (
        <DataModal
          name={t('builder.sections.hobby')}
          path="hobbies.items"
          event={ModalEvents.HOBBY_MODAL}
        >
          <div className="grid grid-cols-2 gap-8">
            <EditorContainer
              label={t('shared.forms.name')}
              className="col-span-2"
              IsoCode="en-US"
              {...getFieldProps(formik, schema, 'name')}
            />
          </div>
        </DataModal>
      )}
    </Formik>
  );
};

export default memo(HobbyModal);
