import * as React from "react";
import { connect, getIn } from "formik";
import EditorWrapper from "./EditorWrapper";
import { useDispatch, useSelector } from '../../contexts/ResumeContext';
import { EditorUtils, ProseMirror } from "@progress/kendo-react-editor";



const EditorContainer = ({
                           formik,
                           IsoCode,
                           onEditorChanged,
                           label,
                           className,
                           isRequired,
                           name,
                           path,
                           value,
                           position,showImprovePosition,
                           degree,showImproveDegree,
                           field,showImproveField,
                           institution,showImproveInstitution }) => {

  const editorState = {};
  const dispatch = useDispatch();

  const handleEditorChanges = (state) => {
    editorState[state.isoCode] = ProseMirror.EditorState.create({
      doc: state.doc,
      selection: state.selection,
      html: state.html,
    });

    let html = "";
    html = (editorState["en-US"]) && EditorUtils.getHtml(editorState["en-US"]);
    formik.setFieldValue(name,html,false);
  };


  const stateValue = useSelector(path, '');
  value = path ? stateValue : value;
  return (
            <EditorWrapper
              className={className}
              isRequired={isRequired}
              label={label}
              value={value}
              name={name}
              isoCode={IsoCode}
              position={position} // Passez position à EditorWrapper
              showImprovePosition={showImprovePosition}
              degree={degree}
              showImproveDegree={showImproveDegree}
              field={field}
              showImproveField={showImproveField}
              institution={institution}
              showImproveInstitution={showImproveInstitution}
              onChange={handleEditorChanges}
            />
         )
};

export default connect(EditorContainer);